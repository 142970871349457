/** @format */
import { FLEET_COMPANIES, TESTIMONIALS } from "@roadflex/constants";
import { HeadSeo } from "@roadflex/react-components-www";
import { useRouter } from "next/router";
import { useEffect } from "react";
import CallToAction from "../components/common-components/call-to-action/call-to-action";
import RoadFlexCustomers from "../components/common-components/customer-success/customer-success";
import Testimonials from "../components/common-components/customer-success/testimonials";
import Panel1 from "../components/landing-page/panel1";
import Panel10 from "../components/landing-page/panel10";
import Panel11 from "../components/landing-page/panel11";
import Panel3 from "../components/landing-page/panel3";
import Panel4 from "../components/landing-page/panel4";
import Panel5 from "../components/landing-page/panel5";
import Panel6 from "../components/landing-page/panel6";
import Panel7 from "../components/landing-page/panel7";
import Panel8 from "../components/landing-page/panel8";

export function Index() {
  const router = useRouter();
  useEffect(() => {
    const { query } = router;
    const { scrollTo, testimonials, whyRoadflex } = query;
    let element = null;

    if (scrollTo) {
      element = document.querySelector(`#${scrollTo}`);
    } else if (testimonials !== undefined) {
      element = document.querySelector(`#testimonials`);
    } else if (whyRoadflex !== undefined) {
      element = document.querySelector(`#comparison-panel`);
    }
    const header = document.querySelector("#header");
    if (element) {
      window.scrollTo({
        top:
          element.getBoundingClientRect().top +
          window.scrollY -
          header.getBoundingClientRect().height,
        left: 0,
        behavior: "smooth",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query]);

  return (
    <>
      <HeadSeo />
      <Panel1 />
      <RoadFlexCustomers fleetCompanies={FLEET_COMPANIES} />
      <Panel3 />
      <Panel4 />
      <Panel5 />
      <Panel6 />
      <Panel7 />
      <Panel8 />
      <Testimonials
        testimonials={TESTIMONIALS.filter(
          (testimonial) =>
            testimonial.company !== "Central Valley Services" &&
            testimonial.company !== "Route 6 Logistics Inc.",
        )}
        uiType="vertical"
        header="Modern Fleets Trust RoadFlex As Their Partner"
      />
      <Panel10 />
      <Panel11 />
      <CallToAction></CallToAction>
    </>
  );
}

export default Index;
