/** @format */

import { CheckIcon } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@roadflex/react-components-www";
import getConfig from "next/config";
import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export function AutoPlaySilentVideo(props) {
  const videoRef = useRef(undefined);
  useEffect(() => {
    videoRef.current.defaultMuted = true;
  });
  return (
    <video className={props.className} ref={videoRef} loop autoPlay muted>
      <source src={props.video} type="video/mp4" />
    </video>
  );
}

export default function Panel1() {
  const router = useRouter();

  return (
    <div id="panel-1" className="w-full">
      <div className="relative text-white bg-brown-900 animate-entrance-slow">
        <div className="flex flex-row container 3xl:max-w-[1750px!important] md:items-center relative justify-center md:justify-start w-full  h-[120vh] sm-[140vh] md:h-[80vh]  md:text-left max-h-[1500px]">
          <div
            className="h-[60%] sm:h-[50%] md:h-full w-full flex items-center"
            style={{ zIndex: "1" }}
          >
            <div className="flex flex-col md:w-[48%]">
              <div className="">
                <div className="my-2 text-2xl text-center lg:!leading-snug md:text-left md:text-3xl lg:text-4xl 3xl:text-6xl">
                  <h1 className="font-bold">
                    Simplify Expenses<br></br> With One Card
                  </h1>
                </div>
                <div className="mb-1 font-normal text-center md:text-left lg:text-lg 3xl:text-2xl">
                  <div>
                    One place to manage your fuel, fleet and field expenses,
                    automate financial reporting, and find new ways to save
                    money
                  </div>
                </div>
              </div>

              <div className="flex flex-row justify-center mt-10 md:items-center md:justify-start">
                <span>
                  <Button
                    variant={ButtonVariant.Orange}
                    onClick={() => {
                      router.push({
                        pathname: "/contact-us",
                      });
                    }}
                    className="!px-4 sm:!px-8 font-medium"
                    size={ButtonSize.Large}
                  >
                    Book a Demo
                  </Button>
                </span>
                <span className="ml-4">
                  <Button
                    variant={ButtonVariant.GrayOutlineWhiteText}
                    onClick={() => {
                      router.push({
                        pathname: "/signup",
                      });
                    }}
                    className="!px-4 sm:!px-8 font-medium"
                    size={ButtonSize.Large}
                  >
                    Apply Now
                  </Button>
                </span>
              </div>
              <div className="flex flex-row items-center justify-center pt-3 md:justify-start">
                <div className="flex flex-row mr-1">
                  <StarIcon className="w-4 h-4 text-yellow-300 sm:w-5 sm:h-5" />
                  <StarIcon className="w-4 h-4 text-yellow-300 sm:w-5 sm:h-5" />
                  <StarIcon className="w-4 h-4 text-yellow-300 sm:w-5 sm:h-5" />
                  <StarIcon className="w-4 h-4 text-yellow-300 sm:w-5 sm:h-5" />
                  <StarIcon className="w-4 h-4 text-yellow-300 sm:w-5 sm:h-5" />
                </div>
                <div>customer reviews</div>
              </div>
              <div className="flex flex-row items-center justify-center pt-3 font-medium text-center text-md md:pt-5 md:text-left md:justify-start">
                <div className="flex flex-col gap-2">
                  <div className="mr-4">
                    <div className="flex flex-row items-center">
                      <div className="flex items-center justify-center">
                        <CheckIcon className="w-4 h-4 mr-1 text-green sm:w-5 sm:h-5" />
                      </div>
                      <div className="pl-1">
                        Visa Business Card, accepted everywhere
                      </div>
                    </div>
                  </div>
                  <div className="mr-4">
                    <div className="flex flex-row items-center">
                      <div className="flex items-center justify-center">
                        <CheckIcon className="w-4 h-4 mr-1 text-green sm:w-5 sm:h-5" />
                      </div>
                      <div className="pl-1">
                        Competitive Discounts & Rebates
                      </div>
                    </div>
                  </div>
                  <div className="mr-4">
                    <div className="flex flex-row items-center">
                      <div className="flex items-center justify-center">
                        <CheckIcon className="w-4 h-4 mr-1 text-green sm:w-5 sm:h-5" />
                      </div>
                      <div className="pl-1">
                        Dedicated customer success and 7-day support
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="md:h-full flex justify-center lg:block w-full h-[40%] sm:h-[50%] md:max-w-[50vw] absolute bottom-0 md:right-0"
          style={{ zIndex: "1" }}
        >
          <AutoPlaySilentVideo
            className="object-cover h-full"
            video={`${NEXT_PUBLIC_CDN_URL}/landing_page/video/black-video.mp4`}
          ></AutoPlaySilentVideo>
          {/* <video autoPlay loop className="object-cover h-full">
            <source
              src={`${NEXT_PUBLIC_CDN_URL}/landing_page/video/black-video.mp4`}
            />
            Your browser does not support the video tag.
          </video> */}
        </div>
      </div>
    </div>
  );
}
