/** @format */
import { CheckIcon } from "@heroicons/react/outline";
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@roadflex/react-components-www";
import getConfig from "next/config";
import Image from "next/image";
import { useRouter } from "next/router";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export default function Panel5() {
  const router = useRouter();
  const redirectTo = (page) => {
    router.push({
      pathname: `/${page}`,
    });
  };
  const goToContactUsPage = (partnerName) => {
    if (partnerName) {
      router.push({
        pathname: "/contact-us",
        query: { partner: partnerName },
      });
    } else {
      router.push({
        pathname: "/contact-us",
      });
    }
  };

  return (
    <div
      id="panel-5"
      className="flex items-center justify-center w-full text-brown-900 my-14 md:my-32"
    >
      <div className="container w-full">
        <div className="mb-16">
          <div className="mb-8 text-3xl font-bold text-center md:text-4xl 3xl:text-5xl">
            Your Legacy Fuel Card Is Costly And Difficult To Work With
          </div>
          <div className="text-base font-medium text-center">
            <span className="text-orange-500">
              For fleet operators and owners:
            </span>{" "}
            are you tired of fuel fraud, card misuse and unresponsive customer
            service?
          </div>
          <div className="text-base font-medium text-center">
            <span className="text-orange-500">
              For controllers and finance teams:
            </span>{" "}
            are you tired of excessive hidden fees, and tedious manual fuel
            reporting?
          </div>
          <div className="text-base font-medium text-center">
            <span className="text-orange-500">For drivers:</span> are you tired
            of getting stuck at the pump or not having a payment option for
            emergencies?
          </div>
        </div>
        <div className="relative flex flex-col w-full mt-14 md:mt-32">
          <div className="container relative flex flex-col items-center justify-center md:flex-row">
            <div className="flex flex-col w-full gap-y-10 md:gap-y-16 xl:gap-y-32 place-items-center">
              <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                <div className="md:w-[50%]">
                  <div className=" max-w-[400px] xl:max-w-[500px]">
                    <div>
                      <div className="mb-4 text-xl font-bold text-center md:text-left md:mb-8 md:text-3xl">
                        Business Visa Card For All Your Expenses
                      </div>
                      <div className="flex flex-col gap-3 text-base md:text-base">
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Use RoadFlex physical and virtual cards anywhere VISA
                          is accepted.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          One card for all of your fuel, fleet, field and
                          business expenses.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Never worry about out-of-network or per transaction
                          fees again.
                        </div>
                      </div>

                      {/* <Button
                        variant={ButtonVariant.Transparent}
                        className="!pl-0 mt-4 mx-auto md:ml-0"
                        onClick={() => {
                          redirectTo("integrations");
                        }}
                      >
                        Learn More{" "}
                        <ArrowCircleRightIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="relative md:w-[50%] order-1 lg:order-2 w-[300px]">
                  <Image
                    layout="responsive"
                    width={750}
                    height={500}
                    objectFit="contain"
                    priority={true}
                    className="relative "
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/expense_management_new/business-visa-card.png`}
                    alt="Business visa card for all your expenses"
                  ></Image>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                <div className="md:w-[50%] order-1 md:order-2">
                  <div className="  max-w-[400px] xl:max-w-[480px]">
                    <div>
                      <div className="mb-4 text-xl font-bold text-center md:mb-8 md:text-3xl md:text-left">
                        Set Precise Spending Limits and Policies
                      </div>
                      <div className="flex flex-col gap-3 text-base md:text-base">
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Set rules and spending limits for each card or driver,
                          issue cards instantly and authorize payments in
                          real-time.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          See where every dollar is spent on, for every driver
                          and vehicle.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Put a stop to wasteful spending and card misuse.
                        </div>
                      </div>
                      {/* <Button
                        variant={ButtonVariant.Transparent}
                        className="!pl-0 mt-4 mx-auto md:ml-0"
                        onClick={() => {
                          redirectTo("integrations");
                        }}
                      >
                        Learn More{" "}
                        <ArrowCircleRightIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="relative md:w-[50%] order-2 md:order-1 w-[300px]">
                  <Image
                    layout="responsive"
                    width={718}
                    height={552}
                    objectFit="contain"
                    priority={true}
                    className="relative"
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/expense_management_new/spend-limits.png`}
                    alt="Set precise spending limits and policies"
                  ></Image>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                <div className="md:w-[50%] ">
                  <div className="max-w-[400px] xl:max-w-[480px]">
                    <div>
                      <div className="mb-4 text-xl font-bold text-center md:mb-8 md:text-3xl md:text-left">
                        Eliminate Fraud and Card Misuse. No More Worrying.
                      </div>
                      <div className="flex flex-col gap-3 text-base md:text-base">
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          RoadFlex automatically verifies that each purchase is
                          legitimate.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          You don&apos;t have to worry about card misuse or
                          fraud anymore, such as drivers filling up their own
                          vehicles or gas cans.
                        </div>
                      </div>
                      {/* <Button
                        variant={ButtonVariant.Transparent}
                        className="!pl-0 mt-4 mx-auto md:ml-0"
                        onClick={() => {
                          redirectTo("integrations");
                        }}
                      >
                        Learn More{" "}
                        <ArrowCircleRightIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="relative md:w-[50%] order-1 lg:order-2 w-[300px]">
                  <Image
                    layout="responsive"
                    width={750}
                    height={500}
                    objectFit="contain"
                    priority={true}
                    className="relative"
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/expense_management_new/eliminate-fraud-card-misuse.png`}
                    alt="Eliminate fraud and card misuse"
                  ></Image>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                <div className="md:w-[50%] order-1 md:order-2">
                  <div className="max-w-[400px] xl:max-w-[480px]">
                    <div>
                      <div className="mb-4 text-xl font-bold text-center md:mb-8 md:text-3xl md:text-left">
                        High Quality Customer Service You Can Depend On
                      </div>
                      <div className="flex flex-col gap-3 text-base md:text-base">
                        You will have a dedicated customer service team working
                        for you, on top of our 24/7 support line accessible
                        through phone, email and SMS.
                      </div>
                      {/* <Button
                        variant={ButtonVariant.Transparent}
                        className="!pl-0 mt-4 mx-auto md:ml-0"
                        onClick={() => {
                          redirectTo("integrations");
                        }}
                      >
                        Learn More{" "}
                        <ArrowCircleRightIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="relative md:w-[50%]  order-2 md:order-1 w-[300px]">
                  <Image
                    layout="responsive"
                    width={750}
                    height={500}
                    objectFit="contain"
                    priority={true}
                    className="relative "
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/expense_management_new/dedicated-customer-success.png`}
                    alt="High quality customer service"
                  ></Image>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                <div className="md:w-[50%] ">
                  <div className="  max-w-[400px] xl:max-w-[480px]">
                    <div>
                      <div className="mb-4 text-xl font-bold text-center md:mb-8 md:text-3xl md:text-left">
                        Exclusive discounts from the RoadFlex Partner Network
                      </div>
                      <div className="flex flex-col gap-3 text-base md:text-base">
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Save up to 10 cents per gallon for fuel purchases.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Get 1% cashback for all non-fuel expenses.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Discounts for fuel, maintenance, tires, repairs,
                          hardware stores and more!
                        </div>
                      </div>
                      {/* <Button
                        variant={ButtonVariant.Transparent}
                        className="!pl-0 mt-4 mx-auto md:ml-0"
                        onClick={() => {
                          redirectTo("integrations");
                        }}
                      >
                        Learn More{" "}
                        <ArrowCircleRightIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="relative md:w-[50%] order-1 lg:order-2 w-[300px]">
                  <Image
                    layout="responsive"
                    width={750}
                    height={500}
                    objectFit="contain"
                    priority={true}
                    className="relative"
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/expense_management_new/exclusive-discounts.png`}
                    alt="Exclusive discounts"
                  ></Image>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mt-14 md:mt-32">
          <Button
            variant={ButtonVariant.Orange}
            onClick={() => goToContactUsPage(null)}
            className="!px-4 sm:!px-8 font-medium"
            size={ButtonSize.Large}
          >
            Book a Demo
          </Button>
        </div>
      </div>
    </div>
  );
}
