/** @format */
import { CheckIcon } from "@heroicons/react/outline";
import getConfig from "next/config";
import Image from "next/image";
import { useRouter } from "next/router";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export default function Panel8() {
  const router = useRouter();
  const redirectTo = (page) => {
    router.push({
      pathname: `/${page}`,
    });
  };

  return (
    <div
      id="panel-8"
      className="flex items-center justify-center w-full text-brown-900 my-14 md:my-32"
    >
      <div className="container w-full">
        <div className="mb-16">
          <div className="mb-8 text-3xl font-bold text-center md:text-4xl 3xl:text-5xl">
            All Of Your Expenses, With One Card
          </div>
          <div className="text-base font-medium text-center">
            Stop wasting significant time managing different cards for your
            business.
          </div>
          <div className="text-base font-medium text-center">
            Use one card for all of your fuel, fleet, field and business
            expenses, with automated financial reporting.
          </div>
        </div>
        <div className="relative flex flex-col w-full mt-14 md:mt-32">
          <div className="container relative flex flex-col items-center justify-center md:flex-row">
            <div className="flex flex-col w-full gap-y-10 md:gap-y-16 xl:gap-y-32 place-items-center">
              <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                <div className="md:w-[50%]">
                  <div className=" max-w-[400px] xl:max-w-[500px]">
                    <div>
                      <div className="mb-4 text-xl font-bold text-center md:text-left md:mb-8 md:text-3xl">
                        Comprehensive Card Controls
                      </div>
                      <div className="flex flex-col gap-3 text-base md:text-base">
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Allow your employees to expense fuel, fleet, field or
                          business expenses on a single VISA card.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Pick and choose the specific merchants types to allow
                          your team to spend on.
                        </div>
                      </div>

                      {/* <Button
                        variant={ButtonVariant.Transparent}
                        className="!pl-0 mt-4 mx-auto md:ml-0"
                        onClick={() => {
                          redirectTo("integrations");
                        }}
                      >
                        Learn More{" "}
                        <ArrowCircleRightIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="relative md:w-[50%] order-1 lg:order-2 w-[300px]">
                  <Image
                    layout="responsive"
                    width={678}
                    height={563}
                    objectFit="contain"
                    priority={true}
                    className="relative "
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_management/comprehensive-card-controls.png`}
                    alt="Comprenhensive card controls"
                  ></Image>
                </div>
              </div>
              <div className="flex flex-col items-center justify-between w-full md:flex-row gap-y-2 md:gap-x-20">
                <div className="md:w-[50%] order-1 md:order-2">
                  <div className="  max-w-[400px] xl:max-w-[480px]">
                    <div>
                      <div className="mb-4 text-xl font-bold text-center md:mb-8 md:text-3xl md:text-left">
                        Close Your Books Faster! Automatically sync with your
                        accounting tools.
                      </div>
                      <div className="flex flex-col gap-3 text-base md:text-base">
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Automatically prepare, review and export expenses with
                          accounting integrations.
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Save hours of manual work every month and keep
                          accurate records with ease!
                        </div>
                        <div className="flex flex-row items-start">
                          <CheckIcon className="w-5 h-5 mr-3 min-w-[20px] min-h-[20px] text-green"></CheckIcon>
                          Automatically generate Fuel Tax Reports (IFTA).
                        </div>
                      </div>
                      {/* <Button
                        variant={ButtonVariant.Transparent}
                        className="!pl-0 mt-4 mx-auto md:ml-0"
                        onClick={() => {
                          redirectTo("integrations");
                        }}
                      >
                        Learn More{" "}
                        <ArrowCircleRightIcon className="w-4 h-4 ml-2 text-orange-500 sm:w-5 sm:h-5" />
                      </Button> */}
                    </div>
                  </div>
                </div>
                <div className="relative md:w-[50%] order-2 md:order-1 w-[300px]">
                  <Image
                    layout="responsive"
                    width={685.73}
                    height={532}
                    objectFit="contain"
                    priority={true}
                    className="relative"
                    src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/fleet_management/accounting-tools.png`}
                    alt="Accounting tools"
                  ></Image>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
