/** @format */

import getConfig from "next/config";
import Image from "next/image";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export default function Panel3() {
  return (
    <div className="container flex flex-col items-center w-full text-black mb-14 md:mb-28">
      <div className="flex items-center justify-center w-full gap-10 mt-4 md:mt-0">
        <div className="relative w-full max-w-[400px] md:max-w-[90%] h-full">
          {" "}
          <Image
            layout="responsive"
            width={"750"}
            height={"500"}
            loading="eager"
            className=""
            src={`${NEXT_PUBLIC_CDN_URL}/landing_page/images/roadflex-dashboard.png`}
            alt="Roadflex dashboard"
          ></Image>
        </div>
      </div>
    </div>
  );
}
