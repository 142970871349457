/** @format */

import { ArrowCircleRightIcon, ArrowDownIcon } from "@heroicons/react/solid";
import {
  Button,
  ButtonSize,
  ButtonVariant,
} from "@roadflex/react-components-www";
import getConfig from "next/config";
import { useRouter } from "next/router";

const { publicRuntimeConfig } = getConfig();
const { NEXT_PUBLIC_CDN_URL } = publicRuntimeConfig;

export default function Panel4() {
  const router = useRouter();
  const redirectTo = (page) => {
    router.push({
      pathname: `/${page}`,
    });
  };
  const scorecards = [
    {
      id: 1,
      stats: "11%",
      statsDescription:
        "average fuel bill savings when switching to RoadFlex, often hundreds of thousands of dollars",
    },
    {
      id: 2,
      stats: "6 hours",
      statsDescription:
        "average time saved each week on admin and finance tasks",
    },
    {
      id: 3,
      stats: "3 hours",
      statsDescription:
        "average time saved each week on fleet reports and analysis",
    },
  ];

  return (
    <div
      id="panel-4"
      className="flex items-center justify-center w-full bg-brown-900"
    >
      <div className="container w-full space-y-14 md:space-y-24 my-14 md:my-32">
        <div className="text-white ">
          <div className="mb-8 text-3xl font-bold text-center md:text-4xl 3xl:text-5xl">
            Thousands of Fleets Have Switched To RoadFlex
          </div>
          <div className="text-base text-center">
            Increase your profits. Eliminate inefficiencies in your expense
            management system.
          </div>
        </div>
        <div className="flex flex-col items-stretch justify-between w-full h-full space-y-8 text-white lg:space-y-0 lg:flex-row">
          {scorecards.length > 0 &&
            scorecards.map((scorecard, idx: number) => (
              <div
                key={idx}
                // className="flex flex-col items-center h-full py-8 text-center gap-x-6 max-w-[300px] md:py-0 md:basis-1/3"
                className={`flex flex-col items-center h-auto text-center w-full px-8 box-border lg:first:border-r lg:last:border-l`}
              >
                <div className="max-w-[230px] flex flex-col items-center">
                  <div className="">
                    <div className="flex flex-row items-center pb-4 text-5xl font-semibold text-orange-500">
                      <ArrowDownIcon className="w-10 h-10 text-orange-500"></ArrowDownIcon>{" "}
                      {scorecard.stats}
                    </div>
                  </div>
                  <div className="font-semibold">
                    {scorecard.statsDescription}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <Button
          variant={ButtonVariant.Orange}
          size={ButtonSize.Large}
          className="py-4 mx-auto font-bold"
          onClick={() => redirectTo("savings")}
        >
          SAVINGS CALCULATOR
          <ArrowCircleRightIcon className="w-6 h-6 ml-3"></ArrowCircleRightIcon>
        </Button>
      </div>
    </div>
  );
}
